.cursor {
  position: fixed;
  height: 40px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 1px solid var(--white);
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 999999999999;

  &::after {
    position: absolute;
    content: "";
    height: 10px;
    aspect-ratio: 1;
    background-color: red;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    box-shadow: 0px 0px 25px rgba(255, 0, 0, 0.541);
  }
}


@media only screen and (max-width:800px) {
  .cursor{
    display: none !important;
  }
}