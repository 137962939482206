:root {
  --white: #ffffff;
  --black: #1a1a1a;
  --red: #964734;
  --accent: #024950;
  --dark-blue: #012529;
  --blue: #0fa4af;
  --bg-header: #f4e9c9;
  --orange: #e74b23;
  --orange2: #f79b1c;
  --divider: #e9e9e973;
  --light-orange: #afdde5;
  --text: #949494;
  --max-width: 1200px;
  --trans: all 0.4s ease-in-out;
  --shadow: 0px 0px 20px rgba(0, 0, 0, 0.151);
  --unit: 12px;
}

*::before,
*::after,
* {
  box-sizing: border-box;
  // border: 1px solid red;
  margin: 0px;
  font-family: "Poppins", sans-serif;
  position: relative;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  width: 100%;
  overflow-x: hidden;
  font-weight: 700;
  background-color: var(--black);
}

html {
  scroll-behavior: smooth;
}

.section-heading {
  font-size: 45px;
  line-height: 55px;
  font-weight: 800;
  color: var(--blue);
  text-transform: uppercase;
}

p {
  text-align: justify;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 1px;
  word-spacing: 2px;
  font-weight: 300;
  color: var(--text);
}

@media only screen and (max-width: 1000px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: center;
    line-height: 100% !important;
  }
}

// .btn {
//   background: var(--dark-red2);
//   border: none;
//   padding: 10px 10px;
//   border-radius: calc(var(--unit)/3);
//   text-transform: uppercase;
//   font-size: calc(var(--unit)*3 /3);
//   letter-spacing: 1px;
//   position: relative;
//   overflow: hidden;
//   transition: var(--trans);
//   color: var(--white);
//   cursor: pointer;
// }

.bg-img-cover {
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.bg-img-contain {
  background-position: center center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

.parent {
  width: 100%;

  .cont {
    max-width: var(--max-width);
    margin: auto;
    padding: 0px 10px;
    height: 100%;
  }
}

a {
  text-decoration: none;
}
.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  background-color: var(--dark-red2);
}

.swiper-pagination-bullet-active {
  background-color: var(--dark-red2);
}

.swiper-button-next:after,
.swiper-button-prev:after {
  color: var(--dark-red2);
  font-size: 10px;
}

.btn {
  background-color: var(--white);
  padding: var(--unit);
  border-radius: calc(var(--unit) / 2);
  font-weight: 500;
  cursor: pointer;
  text-transform: capitalize;
  color: var(--dark-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: var(--trans);
  border: 1px solid var(--white);


  &:hover {
    border: 1px solid var(--white);
    background-color: transparent;
    color: var(--white);
  }
}
.btn2 {
  background-color: var(--black);
  padding: var(--unit);
  border-radius: calc(var(--unit) / 2);
  font-weight: 500;
  cursor: pointer;
  text-transform: capitalize;
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: var(--trans);
  border: 1px solid var(--white);


  &:hover {
    border: 1px solid var(--black);
    background-color: transparent;
    color: var(--black);
  }
}

.title {
  color: var(--white);
  font-size: calc(var(--unit) * 3);
  font-weight: 300;

  .strong {
    font-weight: 800;
  }
}
