.headear-parent {
  width: 150px;
  height: 100vh;
  background-color: transparent;
  z-index: 999999;
  margin-left: 20px;
  padding: 10px;
  position: fixed;
  top: 0;

  .header-menu {
    height: 100%;
    width: 40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 10px;
    flex-direction: column;
    gap: calc(var(--unit)*2);

    &.active {
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(16px) saturate(180%);
      background-color: rgba(32, 32, 32, 0.212);
      border-radius: 12px;
      border: 1px solid rgba(209, 213, 219, 0.3);
    }

    .top {
      height: 70%;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      gap: calc(var(--unit) * 6);

      .img {
        width: 100%;
        aspect-ratio: 1;
        cursor: pointer;
        transform: rotate(0deg);

        img {
          height: 100%;
          width: 100%;
        }
      }

      a {
        height: auto;
        width: 100px;
        color: var(--white);
        // padding: 20px;
        transform: rotate(-90deg);
        font-size: var(--unit);
        cursor: pointer;
        &:hover {
          color: rgba(255, 255, 255, 0.315);
        }
        &:active {
          color: var(--dark-blue);
        }
      }
    }

    .sociaL-icons {
      width: 100%;
      height: 30%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: var(--unit);

      a {
        font-size: 25px;
        color: var(--white);
        cursor: pointer;
      }
    }
  }
}

.header-mob-menu {
  width: 100%;
  height: 70px;
  background-color: var(--white);
  display: none;
  padding: calc(var(--unit) / 2);
  display: none;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;

  .name {
    font-size: calc(var(--unit) * 2.5);
  }

  .right {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--unit);
  }

  .hamburger {
    font-size: calc(var(--unit) * 2);
  }

  .mobnav-list {
    width: 100%;
    height: 100svh;
    background-color: black;
    position: fixed;
    top: 8.5%;
    right: 0;
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 50px;
    align-items: center;
    transform: translateX(100%);
    transition: var(--trans);

    &.active{
      transform: translateX(0);


    }

    .top {
      display: flex;
      width: 100%;
      height: auto;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      a {
        color: var(--white);
        padding: var(--unit);
        font-size: calc(var(--unit) * 2);
      }
    }

    .contact-icon-box {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: var(--unit);
      .icon {
        background-color: var(--white);
        padding: var(--unit);
        border-radius: calc(var(--unit) / 2);
        border: 1px solid var(--white);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: var(--trans);
        cursor: pointer;

        &:hover {
          background-color: transparent;
          border: 1px solid var(--white);
          color: var(--white);
        }
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .headear-parent {
    right: 5%;
    margin-left: 0px;
    margin-right: 0px;
    display: flex;
    justify-content: flex-end;

    padding-right: 0px !important;
  }
}

@media (max-width: 750px) {
  .header-menu {
    display: none !important;
  }

  .header-mob-menu {
    display: flex !important;
  }
}
@media (max-width: 400px) {
  .header-menu {
    display: none !important;
  }

  .header-mob-menu {
    display: flex !important;


    .name{
      font-size: calc(var(--unit)*1.2) !important;
    }
  }
}
