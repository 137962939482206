.about-section-parent {
  height: auto;
  width: 100%;
  background-color: var(--white);
  // background-color: rgb(109, 109, 109);

  .about-section-cont {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    // flex-direction: row-reverse;
    gap: var(--unit);

    .left {
      width: 50%;
      aspect-ratio: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      .aboutimg {
        width: 100%;
        height: 100%;
        background-image: url("../../../assets/cartoon-573.gif");
      }
    }

    .right {
      width: 50%;
      height: auto;
      // aspect-ratio: 1;
      // background-color: red;
      display: flex;
      justify-content: center;
      align-items: first start;
      flex-direction: column;
      gap: calc(var(--unit) * 2);
      .top {
        width: 100%;
        height: fit-content;
        display: flex;
        gap: var(--unit);
        justify-content: center;
        align-items: flex-end;
        h3 {
          width: 50%;
          font-size: 55px;
          color: var(--black);
          line-height: 100%;
        }
        .line {
          width: 50%;
          height: 2px;
          background-color: var(--black);
          margin-bottom: 10px;
        }
      }

      .about-me {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: calc(var(--unit) * 2);
        flex-direction: column;
        .about-para {
          width: 100%;
        }

        .sections {
          width: 100%;
          display: flex;
          flex-direction: column;

         
        }
      }
    }
  }


}



@media only screen and (max-width: 1100px) {
  .about-section-parent {
    height: auto !important;

    .about-section-cont {
      .right {
        .top {
          justify-content: flex-start;
          h3 {
            font-size: 24px;
            text-align: left;
          }

          .line {
            display: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .about-section-cont {
    flex-direction: column;
    padding: 20px !important;
    .left {
     aspect-ratio: 1 !important;
      width: 100% !important;
      justify-content: center !important;
      align-items: center !important;
      .aboutimg {
        height: 100% !important;
        width: 100% !important;
      }
    }

    .right {
      width: 100% !important;
      height: auto !important;
      justify-content: flex-start !important;
      align-items: flex-start !important;
    }
  }
}

@media only screen and (max-width: 700px) {
  .about-section-parent {
    height: auto;
  }
  .left {
    width: 100%;
    height: 30% !important;
    background-size: contain !important;
  }

  .right {
    height: auto !important;
  }
}

.skill-process{
  width: 100%;
  height: auto;

  background-color: var(--black);

  padding: 50px 0px;

 .skill-cont{
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--unit);
  display: flex;
  
  .heading{


    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    h3{
      color: var(--white);
      font-size: calc(var(--unit)*3);
      font-weight: 300;

      .strong{
        font-weight: 800;
      }
    }
  }

  .skils{
    display: grid;
    width: 100%;
    height: 500px;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2,1fr);
    gap: var(--unit);


    .skill{
      border: 1px solid var(--white);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: var(--unit);
      cursor: pointer;
      transition: var(--trans);

      .icon{
        font-size: 50px;
        color: var(--white);
      }

      p{
        color: var(--white);
      }

      &:hover{
        background-color: var(--white) ;


        .icon{
         color: var(--black);
        }

        p{
         color: var(--black);

        }
      }
    }
  }
 }
}

@media only screen and (max-width:900px) {

  .skils{
    height: 600px ;
    grid-template-columns: repeat(4, 1fr) ;
    grid-template-rows: repeat(3,1fr) ;


    .skill{
      // &:nth-child(9){
      //   grid-area: 3 / 2 / 4 / 3 ;
      // }

      // &:nth-child(10){
      //   grid-area: 3 / 3 / 4 / 4; 
      // }
    }


  }
}

@media only screen and (max-width:650px){
.skils{
  height: 800px !important;
  grid-template-columns: repeat(2, 1fr) !important;
  grid-template-rows: repeat(5, 1fr) !important;
}
}