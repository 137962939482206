.project-parent {
  height: auto;
  width: 100%;
  background-color: var(--white);
  padding: 50px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--unit);


  &.blog_parent{
    background-color: var(--black);

    .project-cont{

      .firstProject{
        border: 1px solid var(--white);
      }
    }
  }

  .heading-cont {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .project-cont {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: calc(var(--unit) * 3);
    .firstProject {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: calc(var(--unit) * 2);
      border: 1px solid var(--black);
      overflow: hidden;
      border-radius: var(--unit);


      &:nth-child(even){
        flex-direction: row-reverse;
      }

      .image {
        width: 30%;
        aspect-ratio: 1;
      }
      .right {
        width: 70%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: var(--unit);
        padding: 0px 20px;


.project-title{
  font-size: 24px;
}
        .link{
          font-size: calc(var(--unit)*2);
          transition: var(--trans);

          &:hover{
            color: blue;
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 700px) {
  .firstProject{
    flex-direction: column !important;
    padding: 20px 0px;

    .image{
      width: 100% !important;
    }

    .right{
      width: 100% !important;
    }
  }
}
