@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Sansita:ital,wght@0,400;0,700;0,800;0,900;1,400;1,700;1,800;1,900&display=swap");

.home-parent {
  width: 100%;
  height: 100svh;
  background-color: var(--black);
  // background-color: red;

  .home-cont {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;

    .left {
      width: 50%;
      // height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0px 20px;
      gap: calc(var(--unit) * 2);

      .heading {
        color: var(--white);
        font-size: 24px;
        font-weight: 700;
      }

      .position {
        font-family: "Sansita", sans-serif;
        font-weight: 700;
        font-style: normal;
        color: var(--white);
        font-size: 100px;
        line-height: 100%;
        display: flex;
        flex-direction: column;
        gap: var(--unit);
        text-align: left;
        justify-content: flex-start;
        align-items: flex-start;
        .postion-text,
        .developer-text {
          width: fit-content;
          height: inherit;
          position: relative;
          padding: 5px;
          overflow: hidden;

          .text1 {
            overflow: hidden;
          }
          .swipediv {
            width: 100%;
            height: 110%;
            background-color: var(--white);
            position: absolute;
            top: 0;
            left: -100%;
            z-index: 1;
            animation: swipetext 6s infinite linear;

            @keyframes swipetext {
              0% {
                left: -100%;
              }
              50% {
                left: 100%;
              }
              75% {
                left: 100%;
              }
              100% {
                left: -100%;
              }
            }
          }
        }

        .swipediv2 {
          content: "";
          width: 100%;
          height: 110%;
          background-color: var(--white);
          position: absolute;
          top: 0;
          right: -100%;
          animation: swipe2 6s infinite ease-out;
          z-index: 2;
        }

        @keyframes swipe2 {
          0% {
            right: -100%;
          }
          50% {
            right: 100%;
          }
          75% {
            right: 100%;
          }
          100% {
            right: -100%;
          }
        }
      }

      .para {
        font-size: 16px;
        color: var(--text);
        font-weight: 400;
      }
    }
    .right {
      height: 100%;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 80px 0px 0px 0px;
      .blur {
        -webkit-filter: blur(90px);
        -moz-filter: blur(90px);
        -o-filter: blur(90px);
        -ms-filter: blur(90px);
        filter: blur(100px);
        width: 100%;
        aspect-ratio: 1;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-image: radial-gradient(transparent, white);
        opacity: 30%;
        background-blend-mode: lighten;
      }
      .image {
        height: 100%;
        width: 100%;
        background-image: url("../../assets//output-onlinegiftools.gif");
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .home-parent {
    // height: 600px;
  }
  .image {
    background-size: contain !important;
  }
}
@media only screen and (max-width: 900px) {
  .home-parent {
    height: auto;

    .home-cont {
      padding: 20px 0px 0px 0px !important;
      flex-direction: column;

      .right {
        padding: 0px;
        width: 100%;
        height: 600px !important;
        justify-content: flex-end;
        align-items: flex-end !important;
        .image {
          width: 100%;
          height: 100%;
        }
      }

      .left {
        width: 100% !important;
        .postion-text,
        .position {
          font-size: 50px;
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .postion-text {
    font-size: 44px !important;
  }
}
